import React from 'react'

const Cusotmer = () => {
  return (
    //제작문의
    <div>
      <h1>
        제작문의/프로세스/라이프 사이클
      </h1>
    </div>
  )
}

export default Cusotmer
