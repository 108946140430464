import React from 'react'

const Model = () => {
  return (
    <div>
      <h1>
        3D 모델
      </h1>
    </div>
  )
}

export default Model
