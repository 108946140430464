import React from 'react'

const Introduction = () => {
  return (
    <div>
      <h1>
        디자인 소개
      </h1>
    </div>
  )
}

export default Introduction
