import React from "react";

const Architectural = () => {
  return (
    <div>
      <h1>건축 투시도</h1>
      
    </div>
  );
};

export default Architectural;
